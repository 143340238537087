import { useEffect, useRef, useState } from "react";

import dayjs from "dayjs";
import axios from "axios";
import XLSX from "sheetjs-style";
import { useReactToPrint } from "react-to-print";

import { BsSearch } from "react-icons/bs";
import { FaUser } from "react-icons/fa";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";

import { useLayout } from "@/../_metronic/layout/core";
import { useDebounce } from "@/hooks/useDebounce";
import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { useApi } from "@/hooks/useApi";
import { Paginated } from "@/@types/paginated";
import { OrderBy } from "@/@types/OrderBy";
import { ExportTableType } from "@/@types/ExportTableType";
import { Problem } from "@/@types/Problem";
import { BasePatient } from "@/@types/BasePatient";
import { Attendance } from "@/@types/Attendance";
import { DentalAttendance } from "@/@types/DentalAttendance";
import { Period } from "@/@types/Period";
import { WeightClassificationEnum } from "@/utils/enums/WeightClassificationEnum";
import { RacialColorEnum } from "@/utils/enums/RacialColorEnum";
import { PregnancyTypeEnum } from "@/utils/enums/PregnancyTypeEnum";
import { AttendanceProcedureReqEval } from "@/@types/AttendanceProcedureReqEval";
import { Vaccination } from "@/@types/Vaccination";
import { Cbo } from "@/@types/Cbo";

import { PaginationLinks } from "@/components/PaginationLinks";
import { NoRecordsFeedback } from "@/components/NoRecordsFeedback";
import { Skeleton } from "@/components/Skeleton";
import { SemiDonut } from "@/components/Charts/SemiDonut";
import { PregnancyFilters } from "./PregnancyFilters";
import { PregnancyTable } from "./PregnancyTable";
import { DonutChart } from "@/components/Charts/DonutChart";
import { LoadingScreen } from "@/components/LoadingScreen";
import { PregnancyResume } from "./PregnancyResume";
import { PrintComponent } from "@/components/PrintComponent";

type ResumeGrouped<T, K> = {
	groupKey: T;
	groupCount: number;
	groupList: K[];
};

type ResumePregnant = {
	resumeChildBirthType: ResumeGrouped<string | null, object>[];
	resumePregnancyStatus: ResumeGrouped<string | null, object>[];
	resumePuerperalStatus: ResumeGrouped<string | null, object>[];
	resumeGestationalAge: ResumeGrouped<string | null, object>[];
	resumeGestationalAgeTrimester: ResumeGrouped<number | null, object>[];
	resumeHivAndSifilisStatus: ResumeGrouped<string | null, object>[];
	resumeRacialColor: ResumeGrouped<RacialColorEnum | null, object>[];
	resumeHighRisk: ResumeGrouped<number | null, object>[];
	resumeChildBirthByPeriodAndType: ResumeGrouped<
		Period | null,
		ResumeGrouped<string | null, object>[]
	>[];
	averageGestationalAge: number;
	averagePregnantAge: number;
};

export type Pregnancy = BasePatient & {
	socialName: string;
	firstDumDate: string;
	firstAttendanceDate: string;
	dppDate: string;
	gender: number;
	inPreliminaryReport: boolean;
	preliminaryReportStatus: number;
	phone?: string;
	alcoholicStatus?: number;
	smokerStatus?: number;
	haveDiabetes?: number;
	haveHighBloodPressure?: number;
	lastIndividualRecordDate?: string;
	attendances: Attendance[];
	dentalAttendances?: DentalAttendance[];
	childBirthDate: string;
	puerperalAttendanceProcedures: AttendanceProcedureReqEval[];
	pregnancyProcedures?: AttendanceProcedureReqEval[];
	pregnancyVaccinations?: Vaccination[];
	childBirthType?:
		| "normal"
		| "cesarean"
		| "normalstillbirth"
		| "cesareanstillbirth"
		| "abortion"
		| "unspecified"
		| "unregistered";
	childBirthProblem?: Problem;
	attendanceStatus: string;
	puerperalStatus: string;
	pregnancyStatus: string;
	highRiskStatus?: number;
	plannedPregnancyStatus?: number;
	weightClassification?: WeightClassificationEnum;
	racialColor?: RacialColorEnum;
	pregnancyType?: PregnancyTypeEnum;
	firstGestationalAge?: number;
	lastGestationalAge?: number;
	citizenCbo?: Cbo;
};

export function PregnancyManagement() {
	const [orderBy, setOrderBy] = useState<OrderBy<Pregnancy>[]>([]);
	const api = useApi();
	const { CancelToken } = axios;
	const sourceCancel = CancelToken.source();
	const { ibgeCode, uf } = useCurrentAccount();
	const { setTitle } = useLayout();
	const [searchFullName, setSearchFullName] = useState("");
	const debouncedFullName = useDebounce(searchFullName, 2000);
	const pregnantComponentRef = useRef<HTMLTableElement | null>(null);
	const [isPrinting, setIsPrinting] = useState(false);
	const [selectedOptionStartDate, setSelectedOptionStartDate] = useState<string>(
		dayjs().subtract(1, "months").format("YYYY-MM-DD")
	);
	const [selectedOptionEndDate, setSelectedOptionEndDate] = useState<string>(
		dayjs().format("YYYY-MM-DD")
	);
	const [pageNumber, setPageNumber] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [isLoadingResume, setIsLoadingResume] = useState(false);
	const [pregnants, setPregnants] = useState<Pregnancy[]>([]);
	const [pregnantPaginated, setPregnantPaginated] = useState<Paginated<Pregnancy>>(
		{} as Paginated<Pregnancy>
	);
	const [resumePregnant, setResumePregnant] = useState<ResumePregnant>({} as ResumePregnant);

	async function handleMonitoringPregnantPaginated() {
		try {
			const { data } = await api.get<Paginated<Pregnancy>>(
				`/Attendance/v1/GetPregnantsWithChildBirthPaginated`,
				{
					cancelToken: sourceCancel.token,
					params: {
						startDate: selectedOptionStartDate,
						endDate: selectedOptionEndDate,
						fullName: searchFullName,
						ibgeCode: ibgeCode,
						pageNumber: pageNumber,
						uf: uf,
						orderBy: orderBy
							.map((item) => [item.property, item.method || "asc"].join(" "))
							.join(","),
					},
				}
			);
			return data || ({} as Paginated<Pregnancy>);
		} catch (error) {
			console.log(error);
			return {} as Paginated<Pregnancy>;
		}
	}

	async function handleMonitoringPregnant() {
		try {
			const { data } = await api.get<Pregnancy[]>(
				`/Attendance/v1/GetPregnantsWithChildBirth`,
				{
					cancelToken: sourceCancel.token,
					params: {
						startDate: selectedOptionStartDate,
						endDate: selectedOptionEndDate,
						fullName: searchFullName,
						ibgeCode: ibgeCode,
						pageNumber: pageNumber,
						uf: uf,
						orderBy: orderBy
							.map((item) => [item.property, item.method || "asc"].join(" "))
							.join(","),
					},
				}
			);
			return data || [];
		} catch (error) {
			console.log(error);
			return [];
		}
	}

	async function handleResumePregnant() {
		try {
			const { data } = await api.get<ResumePregnant>(
				`/Attendance/v1/ResumePregnantsWithChildBirth`,
				{
					params: {
						startDate: selectedOptionStartDate,
						endDate: selectedOptionEndDate,
						ibgeCode: ibgeCode,
						uf: uf,
					},
				}
			);
			return data || ({} as ResumePregnant);
		} catch (error) {
			console.log(error);
			return {} as ResumePregnant;
		}
	}

	function handleChangePageNumber(newPage: number) {
		setPageNumber(newPage);
	}

	async function fetchResume() {
		setIsLoadingResume(true);
		setResumePregnant(await handleResumePregnant());
		setIsLoadingResume(false);
	}

	async function fetch() {
		setIsLoading(true);
		setPregnantPaginated(await handleMonitoringPregnantPaginated());
		setIsLoading(false);
	}

	function handleExportToExcel() {
		const dataForExcel = pregnants.map((pregnant) => {
			return {
				NOME: pregnant.fullName,
				DN: dayjs(pregnant.birthdate).format("DD/MM/YYYY"),
				CPF: pregnant.cpf,
				DUM: dayjs(pregnant.firstDumDate).format("DD/MM/YYYY"),
				IG: pregnant.firstGestationalAge,
				PARTO: pregnant.childBirthDate
					? dayjs(pregnant.childBirthDate).format("DD/MM/YYYY")
					: "-",
				"TIPO PARTO":
					pregnant.childBirthType === "normal"
						? "Normal"
						: pregnant.childBirthType === "cesarean"
						? "Cesário"
						: pregnant.childBirthType === "abortion"
						? "Aborto"
						: pregnant.childBirthType === "normalstillbirth"
						? "Natimorto (normal)"
						: pregnant.childBirthType === "cesareanstillbirth"
						? "Natimorto (cesário)"
						: pregnant.childBirthType === "unspecified"
						? "Não especificado"
						: pregnant.childBirthType === "unregistered"
						? "Não registrado"
						: "-",
				PUERPERIO:
					pregnant.puerperalStatus === "finished"
						? "Concluído"
						: pregnant.puerperalStatus === "pending"
						? "Pendente"
						: pregnant.puerperalStatus === "lost"
						? "Perdido"
						: "-",
				CONSULTAS: pregnant.attendances.length || 0,
			};
		});

		const worksheet = XLSX.utils.json_to_sheet(dataForExcel);
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, worksheet, "Pregnants Report");

		XLSX.writeFile(workbook, "pregnants_report.xlsx");
	}

	const handlePrint = useReactToPrint({
		content: () => pregnantComponentRef.current,
	});

	const [pendingExportType, setPendingExportType] = useState<ExportTableType>();

	async function handleExportTable(exportTableType: ExportTableType) {
		setIsPrinting(true);
		const pregnants = await handleMonitoringPregnant();
		setPendingExportType(exportTableType);
		setIsPrinting(false);
		if (pregnants.length > 0) {
			setPregnants(pregnants);
		}
	}

	useEffect(() => {
		if (pregnants.length > 0 && pendingExportType) {
			if (pendingExportType === "print") {
				handlePrint();
			} else if (pendingExportType === "excel") {
				handleExportToExcel();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pregnants]);

	useEffect(() => {
		if (pageNumber !== 0) {
			fetch();
		}
		return () => {
			if (sourceCancel) {
				sourceCancel.cancel("Request canceled because change page.");
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageNumber]);

	useEffect(() => {
		if (pageNumber !== 1) {
			setPageNumber(1);
			return;
		}
		fetch();
		return () => {
			if (sourceCancel) {
				sourceCancel.cancel("Request canceled because change filters.");
			}
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedFullName, orderBy]);

	useEffect(() => {
		setTitle("SAÚDE DA GESTANTE");
		fetchResume();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="mx-2" style={{ minWidth: "320px" }}>
			<LoadingScreen loading={isPrinting} />
			<Card className="my-2">
				<Card.Body className="m-0 p-4">
					<Form
						className="mt-2"
						onSubmit={(event) => {
							event.preventDefault();
							fetchResume();
						}}
					>
						<Row className="d-flex justify-content-between">
							<Col sm={12} lg={4} className="d-flex align-items-center gap-3">
								<Form.Label className="align-items-center fw-bolder">
									Período
								</Form.Label>
								<InputGroup className="mb-3">
									<Form.Control
										type="date"
										placeholder="Data inicial"
										min={dayjs().subtract(2, "years").format("YYYY-MM-DD")}
										max={dayjs().format("YYYY/MM/DD")}
										defaultValue={dayjs().subtract(1, "M").format("YYYY-MM-DD")}
										onChange={(event) =>
											setSelectedOptionStartDate(event.target.value)
										}
									/>
								</InputGroup>
								<InputGroup className="mb-3">
									<Form.Control
										type="date"
										placeholder="data final"
										defaultValue={dayjs().format("YYYY-MM-DD")}
										min={selectedOptionStartDate}
										max={dayjs().format("YYYY-MM-DD")}
										onChange={(event) =>
											setSelectedOptionEndDate(event.target.value)
										}
									/>
								</InputGroup>
							</Col>
							<Col sm={12} lg={2}>
								<Button type="submit" className="w-100" disabled={isLoading}>
									<BsSearch className="me-2" />
									Consultar
								</Button>
							</Col>
						</Row>
					</Form>
				</Card.Body>
			</Card>
			<PregnancyResume
				totalPregnants={
					resumePregnant.resumePregnancyStatus
						?.map((resume) => resume.groupCount)
						.reduce((curr, acc) => curr + acc, 0) || 0
				}
				totalRegisteredChildBirth={
					resumePregnant?.resumeChildBirthType
						?.filter((resume) => resume.groupKey !== "unregistered")
						.map((resume) => resume.groupCount)
						.reduce((curr, acc) => curr + acc, 0) || 0
				}
				totalUnregisteredChildBirth={
					resumePregnant?.resumeChildBirthType
						?.filter((resume) => resume.groupKey === "unregistered")
						.map((resume) => resume.groupCount)
						.reduce((curr, acc) => curr + acc, 0) || 0
				}
				totalRiskPregnants={
					resumePregnant.resumeHighRisk
						?.filter((resume) => resume.groupKey === 1)
						.map((resume) => resume.groupCount)
						.reduce((curr, acc) => curr + acc, 0) || 0
				}
				averagePregnantAge={resumePregnant?.averagePregnantAge || 0}
				averageGestationalAge={resumePregnant?.averageGestationalAge || 0}
			/>
			<Row className="w-100 my-5 d-flex justify-content-stretch mx-0">
				<Col
					md={8}
					lg={8}
					xl={8}
					className="d-flex flex-column align-items-center justify-content-center mb-3"
				>
					<Card className="w-100 h-100">
						<Card.Header>
							<Card.Title>PROPORÇÃO DE CAPTAÇÃO PRECOCE</Card.Title>
						</Card.Header>
						<Card.Body className="d-flex justify-content-center align-items-center p-0">
							{!isLoadingResume &&
							resumePregnant?.resumePregnancyStatus &&
							resumePregnant?.resumeChildBirthType ? (
								<DonutChart
									series={[
										resumePregnant?.resumeGestationalAgeTrimester.find(
											(resume) => resume.groupKey === 1
										)?.groupCount || 0,
										resumePregnant?.resumeGestationalAgeTrimester.find(
											(resume) => resume.groupKey === 2
										)?.groupCount || 0,
										resumePregnant?.resumeGestationalAgeTrimester.find(
											(resume) => resume.groupKey === 3
										)?.groupCount || 0,
									]}
									labels={["1º Trimestre", "2º Trimestre", "3º Trimestre"]}
									colors={["#008ffb", "#00e396", "#ffc700"]}
									width={450}
								/>
							) : (
								<Skeleton className="my-2" width={250} height={250} circle />
							)}
							{/*
								<DonutChart
									series={[
										resumePregnant?.resumePregnancyStatus.find(
											(resume) => resume.groupKey === "pregnant"
										)?.groupCount || 0,
										resumePregnant?.resumePregnancyStatus.find(
											(resume) => resume.groupKey === "puerperal"
										)?.groupCount || 0,
										resumePregnant?.resumePregnancyStatus.find(
											(resume) => resume.groupKey === "finished"
										)?.groupCount || 0,
									]}
									labels={["Pré natal", "Puerpério", "Acompanhamento finalizado"]}
									colors={["#00e396", "#ffc700", "#008ffb"]}
									width={450}
								/>*/}
						</Card.Body>
					</Card>
				</Col>

				<Col
					md={4}
					lg={4}
					xl={4}
					className="d-flex flex-column align-items-center justify-content-center"
				>
					<Card className="w-100 justify-content-center align-items-center">
						<Card.Header>
							<Card.Title className="d-flex justify-content-center w-100">
								FINALIZAÇÕES GESTACIONAIS
							</Card.Title>
						</Card.Header>
						{!isLoadingResume && resumePregnant?.resumeChildBirthType ? (
							<SemiDonut
								series={[
									resumePregnant?.resumeChildBirthType?.find(
										(t) => t.groupKey === "normal"
									)?.groupCount || 0,
									resumePregnant?.resumeChildBirthType?.find(
										(t) => t.groupKey === "cesarean"
									)?.groupCount || 0,
									resumePregnant?.resumeChildBirthType?.find(
										(t) => t.groupKey === "abortion"
									)?.groupCount || 0,
									resumePregnant?.resumeChildBirthType?.find(
										(t) => t.groupKey === "normalstillbirth"
									)?.groupCount || 0,
									resumePregnant?.resumeChildBirthType?.find(
										(t) => t.groupKey === "cesareanstillbirth"
									)?.groupCount || 0,
									resumePregnant?.resumeChildBirthType?.find(
										(t) => t.groupKey === "unspecified"
									)?.groupCount || 0,
									resumePregnant?.resumeChildBirthType?.find(
										(t) => t.groupKey === "unregistered"
									)?.groupCount || 0,
								]}
								descriptionsLabels={[
									"Normal",
									"Cesário",
									"Aborto",
									"Natimorto (normal)",
									"Natimorto (cesário)",
									"Não especificado",
									"Não registrado",
								]}
								height={200}
								colors={[
									"#008ffb",
									"#00e396",
									"#feb019",
									"#ff4560",
									"#775dd0",
									"#998877",
									"#bbbbbb",
								]}
							/>
						) : (
							<Skeleton className="my-2" width={150} height={150} circle />
						)}
					</Card>
					<Card className="w-100 my-2 justify-content-center align-items-center">
						<Card.Header>
							<Card.Title className="d-flex justify-content-center w-100">
								CONSULTA PUERPERAL (ATÉ 42º DIA)
							</Card.Title>
						</Card.Header>
						{!isLoadingResume && resumePregnant?.resumePuerperalStatus ? (
							<SemiDonut
								series={[
									resumePregnant?.resumePuerperalStatus?.find(
										(s) => s.groupKey === "finished"
									)?.groupCount || 0,
									resumePregnant?.resumePuerperalStatus?.find(
										(s) => s.groupKey === "pending"
									)?.groupCount || 0,
									resumePregnant?.resumePuerperalStatus?.find(
										(s) => s.groupKey === "lost"
									)?.groupCount || 0,
								]}
								descriptionsLabels={["Realizado", "Pendente", "Fora do prazo"]}
								height={200}
								colors={["#009ef7", "#ffc700", "#d9214e"]}
							/>
						) : (
							<Skeleton className="my-2" width={150} height={150} circle />
						)}
					</Card>
				</Col>
			</Row>
			<Row className="my-2">
				<Col>
					<Card>
						<Card.Body>
							<PregnancyFilters
								fullName={searchFullName}
								setFullName={setSearchFullName}
								handleExportTable={handleExportTable}
								isPrinting={isPrinting}
							/>
							<PregnancyTable
								isLoading={isLoading}
								pregnantsWithChildBirth={pregnantPaginated.data}
								orderBy={orderBy}
								setOrderBy={setOrderBy}
							/>
							<PrintComponent
								printComponentRef={pregnantComponentRef}
								displayCurrentDate
							>
								<div className="w-100">
									<div className="mt-2 mb-2 text-center">
										<h3>
											Relatório Gestantes -{" "}
											{`${dayjs(selectedOptionStartDate).format(
												"DD/MM/YYYY"
											)} a ${dayjs(selectedOptionEndDate).format(
												"DD/MM/YYYY"
											)}`}
										</h3>
									</div>
									<PregnancyTable
										isLoading={isLoading}
										pregnantsWithChildBirth={pregnants}
									/>
								</div>
							</PrintComponent>
						</Card.Body>
						<Card.Footer>
							{pregnantPaginated?.totalRecords ? (
								<PaginationLinks
									itemsPerPage={pregnantPaginated.pageSize}
									totalPages={pregnantPaginated.totalRecords}
									changeSelectedPage={handleChangePageNumber}
									pageNumber={pageNumber || 0}
								/>
							) : (
								!isLoading &&
								pregnantPaginated?.totalRecords === 0 && (
									<NoRecordsFeedback
										message={"Nenhum resultado encontrado."}
										icon={<FaUser />}
									/>
								)
							)}
						</Card.Footer>
					</Card>
				</Col>
			</Row>
		</div>
	);
}
