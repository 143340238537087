import "./styles.css";

export type TimeLineContent = {
	eventDate: string;
	content: React.ReactNode;
};

type Props = {
	timeLineTitle: string;
	timeLineContents?: TimeLineContent[];
};

export function TimeLine({ timeLineTitle, timeLineContents }: Props) {
	return (
		<div className="container">
			<div className="row">
				<div className="col-md-12">
					<div className="card">
						<div className="card-body">
							<h6 className="card-title">{timeLineTitle}</h6>
							<div id="content">
								<ul className="timeline">
									{timeLineContents?.map((timeLinecontent) => (
										<li className="event" data-date={timeLinecontent.eventDate}>
											{timeLinecontent.content}
										</li>
									))}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
