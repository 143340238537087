import { useEffect, useState } from "react";

import dayjs from "dayjs";

import { BsSearch } from "react-icons/bs";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

import { useApi } from "@/hooks/useApi";
import { useUnits } from "@/hooks/useUnits";
import { SHORT_MONTHS } from "@/utils/enums/DateEnum";
import { Professional } from "@/@types/Professional";
import { HealthUnit } from "@/@types/HealthUnit";
import { HealthTeam } from "@/@types/HealthTeam";
import { Option } from "@/@types/Option";

import { GlobalInformationVisits } from "./Globalnformation";
import { LoadingScreen } from "@/components/LoadingScreen";
import { AreaChart } from "@/components/Charts/AreaChart";
import { BarChart } from "@/components/Charts/BarChart";
import { UnitsSelect } from "@/components/UnitsSelect";

export type TerritorialCitizenResume = {
	totalCitizens: number;
	totalMC: number;
	totalFA: number;
	totalFamilies: number;
	totalResidences: number;
	totalOtherBuildings: number;
	totalAcs: number;
};

type Props = {
	uf: string;
	ibgeCode: string;
	cnes: string;
};

type ResumeHealthUnit = {
	healthUnit: HealthUnit;
	total: number;
};

type ResumeHealthTeam = {
	healthTeam: HealthTeam;
	total: number;
};

type ResumeByProfessional = {
	professional: Professional;
	total: number;
};

type ResumeByPeriod = {
	period: {
		year: number;
		month: number;
	};
	total: number;
};

export function CitizenAssistance({ uf, ibgeCode, cnes }: Props) {
	const api = useApi();
	const [isLoading, setIsLoading] = useState(false);
	const { units } = useUnits();
	const [selectedHealthUnit, setSelectedHealthUnit] = useState<Option<HealthUnit>>(
		{} as Option<HealthUnit>
	);
	const [keyMenuRegistration, setKeyMenuRegistration] = useState<string | null>("new");
	const [keyMenuCitizen, setKeyMenuCitizen] = useState<string | null>("healthTeams");
	const [territorialCitizenResume, setTerritorialCitizenResume] =
		useState<TerritorialCitizenResume>({} as TerritorialCitizenResume);
	const [resumeHealthUnits, setResumeHealthUnits] = useState<ResumeHealthUnit[]>([]);
	const [resumeHealthTeams, setResumeHealthTeams] = useState<ResumeHealthTeam[]>([]);
	const [resumeByProfessionals, setResumeByProfessionals] = useState<ResumeByProfessional[]>([]);
	const [resumeNewRegistrationsCitizens, setResumeNewRegistrationsCitizens] = useState<
		ResumeByPeriod[]
	>([]);
	const [resumeUpdatedRegistrationsCitizens, setResumeUpdatedRegistrationsCitizens] = useState<
		ResumeByPeriod[]
	>([]);

	const unitCurrentDefault = units?.find((unit) => unit.cnes === cnes);

	async function handleTerritorialCitizenResume() {
		try {
			const { data } = await api.get<TerritorialCitizenResume>(
				`TerritorialCitizen/v1/getResume`,
				{
					params: {
						uf,
						ibgeCode,
						cnes: selectedHealthUnit.value || cnes,
					},
				}
			);
			return data || ({} as TerritorialCitizenResume);
		} catch (error) {
			console.log(error);
			return {} as TerritorialCitizenResume;
		}
	}

	async function handleResumeByHealthUnits() {
		try {
			const { data } = await api.get<ResumeHealthUnit[]>(
				`TerritorialCitizen/v1/GetResumeByHealthUnit`,
				{
					params: {
						uf,
						ibgeCode,
						cnes: selectedHealthUnit.value || cnes,
					},
				}
			);
			return data || [];
		} catch (error) {
			console.log(error);
			return [];
		}
	}

	async function handleResumeByHealthTeams() {
		try {
			const { data } = await api.get<ResumeHealthTeam[]>(
				`TerritorialCitizen/v1/GetResumeByHealthTeam`,
				{
					params: {
						uf,
						ibgeCode,
						cnes: selectedHealthUnit.value || cnes,
					},
				}
			);
			return data || [];
		} catch (error) {
			console.log(error);
			return [];
		}
	}

	async function handleResumeByProfessionals() {
		try {
			const { data } = await api.get<ResumeByProfessional[]>(
				`TerritorialCitizen/v1/GetResumeByProfessional`,
				{
					params: {
						uf,
						ibgeCode,
						cnes: selectedHealthUnit.value || cnes,
					},
				}
			);
			return data || [];
		} catch (error) {
			console.log(error);
			return [];
		}
	}

	async function handleResumeCitizensUpdatedByPeriod() {
		try {
			const { data } = await api.get<ResumeByPeriod[]>(
				`TerritorialCitizen/v1/GetResumeCitizensUpdatedByPeriod`,
				{
					params: {
						uf,
						ibgeCode,
						cnes: selectedHealthUnit.value || cnes,
						startDate: dayjs().subtract(12, "month").format("YYYY-MM-DD"),
						endDate: dayjs().format("YYYY-MM-DD"),
					},
				}
			);
			return data || [];
		} catch (error) {
			console.log(error);
			return [];
		}
	}

	async function handleResumeNewCitizensByPeriod() {
		try {
			const { data } = await api.get<ResumeByPeriod[]>(
				`TerritorialCitizen/v1/GetResumeNewCitizensByPeriod`,
				{
					params: {
						uf,
						ibgeCode,
						cnes: selectedHealthUnit.value || cnes,
						startDate: dayjs().subtract(12, "month").format("YYYY-MM-DD"),
						endDate: dayjs().format("YYYY-MM-DD"),
					},
				}
			);
			return data || [];
		} catch (error) {
			console.log(error);
			return [];
		}
	}

	async function fetchUpdateRegistersCitizens() {
		setResumeUpdatedRegistrationsCitizens(await handleResumeCitizensUpdatedByPeriod());
	}
	async function fetchNewRegistersCitizens() {
		setResumeNewRegistrationsCitizens(await handleResumeNewCitizensByPeriod());
	}

	async function fetchResumeByProfessionals() {
		setResumeByProfessionals(await handleResumeByProfessionals());
	}

	async function fetchResumeByHealthTeams() {
		setResumeHealthTeams(await handleResumeByHealthTeams());
	}

	async function fetchResumeByHealthUnits() {
		setResumeHealthUnits(await handleResumeByHealthUnits());
	}

	async function fetch() {
		setIsLoading(true);
		const [
			resumeByTerritorialCitizenDb,
			resumeNewRegistersCitizensByPeriodDb,
			resumeHealthTeamsDb,
		] = await Promise.all([
			handleTerritorialCitizenResume(),
			handleResumeNewCitizensByPeriod(),
			handleResumeByHealthTeams(),
		]);

		setTerritorialCitizenResume(resumeByTerritorialCitizenDb);
		setResumeNewRegistrationsCitizens(resumeNewRegistersCitizensByPeriodDb);
		setResumeHealthTeams(resumeHealthTeamsDb);
		setIsLoading(false);
	}

	useEffect(() => {
		fetch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			{!cnes ? (
				<Row className="d-flex justify-content-between mx-3 p-2 bg-white rounded">
					<Col sm={6} md={6} lg={6} xl={6}>
						<UnitsSelect
							units={units}
							onChange={(event) => setSelectedHealthUnit(event as Option<HealthUnit>)}
						/>
					</Col>
					<Col
						sm={2}
						md={2}
						lg={2}
						xl={2}
						className="d-flex align-items-center justify-content-center my-2"
					>
						<Button
							variant="light"
							className="align-items-end w-100"
							onClick={() => {
								setKeyMenuRegistration("new");
								setKeyMenuCitizen("healthTeams");
								fetch();
							}}
						>
							<BsSearch className="fs-1 me-2" />
						</Button>
					</Col>
				</Row>
			) : (
				<h1 className="mx-3 p-2 fs-3 bg-white">{unitCurrentDefault?.name}</h1>
			)}
			<LoadingScreen loading={isLoading} />
			<section className="rounded">
				<GlobalInformationVisits
					totalCitizens={territorialCitizenResume.totalCitizens}
					totalAcs={territorialCitizenResume.totalAcs}
					totalFamily={territorialCitizenResume.totalFamilies}
					totalOtherBuildings={territorialCitizenResume.totalOtherBuildings}
					totalRegistraionFa={territorialCitizenResume.totalFA}
					totalRegistrationMc={territorialCitizenResume.totalMC}
					totalResidences={territorialCitizenResume.totalResidences}
				/>
			</section>
			<section className="mt-5">
				<Row className="m-2">
					<Card>
						<Card.Header>
							<Card.Title>Cadastros nos últimos 12 meses</Card.Title>
							<Nav
								fill
								variant="tabs"
								className="d-flex align-items-center"
								activeKey={keyMenuRegistration!}
								onSelect={(event) => setKeyMenuRegistration(event)}
							>
								<Nav.Item
									onClick={() => {
										fetchNewRegistersCitizens();
									}}
								>
									<Nav.Link
										eventKey="new"
										className="btn btn-sm btn-color-muted btn-active btn-active-secondary fw-bold fs-6 px-4 me-1 cursor-pointer"
									>
										Novos
									</Nav.Link>
								</Nav.Item>
								<Nav.Item
									onClick={() => {
										fetchUpdateRegistersCitizens();
									}}
								>
									<Nav.Link
										eventKey="updated"
										className="btn btn-sm btn-color-muted btn-active btn-active-secondary fw-bold fs-6 px-4 me-1 cursor-pointer"
									>
										Atualizados
									</Nav.Link>
								</Nav.Item>
							</Nav>
						</Card.Header>
						<Card.Body>
							{keyMenuRegistration === "new" ? (
								<AreaChart
									series={[
										{
											name: "Novos cadastros",
											data: resumeNewRegistrationsCitizens?.map(
												(resumeNewRegistration) =>
													resumeNewRegistration?.total
											),
										},
									]}
									categories={resumeNewRegistrationsCitizens?.map(
										(resumeNewRegistration) =>
											`${resumeNewRegistration.period?.year}/${
												SHORT_MONTHS[resumeNewRegistration.period.month - 1]
											}`
									)}
								/>
							) : keyMenuRegistration === "updated" ? (
								<AreaChart
									series={[
										{
											name: "Atualização de cadastros",
											data: resumeUpdatedRegistrationsCitizens?.map(
												(resumeUpdatedRegistration) =>
													resumeUpdatedRegistration?.total
											),
										},
									]}
									categories={resumeUpdatedRegistrationsCitizens?.map(
										(resumeUpdatedRegistration) =>
											`${resumeUpdatedRegistration.period?.year}/${
												SHORT_MONTHS[
													resumeUpdatedRegistration.period?.month - 1
												]
											}`
									)}
								/>
							) : null}
						</Card.Body>
					</Card>
				</Row>
				<Row className="p-2">
					<Col sm={12} lg={12}>
						<Card>
							<Card.Header className="d-flex fs-2 fw-bolder justify-content-between align-items-center">
								<Card.Title>Quantitativo de cidadãos vinculados</Card.Title>
								<Nav
									fill
									variant="tabs"
									className="d-flex align-items-center"
									activeKey={keyMenuCitizen!}
									onSelect={(event) => setKeyMenuCitizen(event)}
								>
									<Nav.Item
										onClick={() => {
											fetchResumeByHealthTeams();
										}}
									>
										<Nav.Link
											eventKey="healthTeams"
											className="btn btn-sm btn-color-muted btn-active btn-active-secondary fw-bold fs-6 px-4 me-1 cursor-pointer"
										>
											Equipes
										</Nav.Link>
									</Nav.Item>
									<Nav.Item
										onClick={() => {
											fetchResumeByHealthUnits();
										}}
									>
										<Nav.Link
											eventKey="healthUnits"
											className="btn btn-sm btn-color-muted btn-active btn-active-secondary fw-bold fs-6 px-4 me-1 cursor-pointer"
										>
											Unidades
										</Nav.Link>
									</Nav.Item>
									<Nav.Item
										onClick={() => {
											fetchResumeByProfessionals();
										}}
									>
										<Nav.Link
											eventKey="professionals"
											className="btn btn-sm btn-color-muted btn-active btn-active-secondary fw-bold fs-6 px-4 me-1 cursor-pointer"
										>
											Profissionais
										</Nav.Link>
									</Nav.Item>
								</Nav>
							</Card.Header>
							<Card.Body style={{ maxHeight: "50rem", overflowY: "auto" }}>
								{keyMenuCitizen === "healthTeams" ? (
									<BarChart
										series={[
											{
												data:
													resumeHealthTeams
														?.map(
															(resumeHealthTeam) =>
																resumeHealthTeam?.total
														)
														?.sort((a, b) => b - a) || [],
												name: "Total",
											},
										]}
										categories={
											resumeHealthTeams
												?.sort((a, b) => b.total - a.total)
												?.map(
													(resumeHealthTeam) =>
														resumeHealthTeam?.healthTeam?.name
												) || []
										}
										categoryTextWidth={160}
									/>
								) : keyMenuCitizen === "healthUnits" ? (
									<BarChart
										series={[
											{
												data:
													resumeHealthUnits
														?.map(
															(resumeHealthUnit) =>
																resumeHealthUnit?.total
														)
														?.sort((a, b) => b - a) || [],
												name: "Total",
											},
										]}
										categories={
											resumeHealthUnits
												?.sort((a, b) => b.total - a.total)
												?.map(
													(resumeHealthUnit) =>
														resumeHealthUnit?.healthUnit?.name
												) || []
										}
										categoryTextWidth={160}
									/>
								) : keyMenuCitizen === "professionals" ? (
									<BarChart
										series={[
											{
												data:
													resumeByProfessionals
														?.map(
															(resumeByProfessional) =>
																resumeByProfessional?.total
														)
														?.sort((a, b) => b - a) || [],
												name: "Total",
											},
										]}
										categories={
											resumeByProfessionals
												?.sort((a, b) => b.total - a.total)
												?.map(
													(resumeByProfessional) =>
														resumeByProfessional?.professional?.name
												) || []
										}
										categoryTextWidth={160}
									/>
								) : null}
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</section>
		</div>
	);
}
