import dayjs from "dayjs";

import { TbClipboardX } from "react-icons/tb";
import { FaSyringe, FaTooth, FaVirus } from "react-icons/fa";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { WeightClassificationEnum } from "@/utils/enums/WeightClassificationEnum";
import { PregnancyTypeEnum } from "@/utils/enums/PregnancyTypeEnum";
import { RacialColorEnum } from "@/utils/enums/RacialColorEnum";

import { TimeLine, TimeLineContent } from "@/components/TimeLine";
import { NoRecordsFeedback } from "@/components/NoRecordsFeedback";
import { Pregnancy } from "@/pages/PregnancyManagement";
import { VaccinationCard, VaccinationCardData } from "@/components/VaccinationCard";

type Props = ModalProps & {
	pregnantWithChildBirth: Pregnancy;
};

export function PregnancyModal({ pregnantWithChildBirth, ...rest }: Props) {
	return (
		<Modal {...rest} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header closeButton>
				<Modal.Title>
					<h3 className="text-uppercase text-dark fw-bold fs-1">SAÚDE DA GESTANTE</h3>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<h3>INFORMAÇÕES PESSOAIS</h3>
				<section className="my-3">
					<Row>
						<Col sm={12} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-4 text-primary">Paciente: </span>
							<span>
								{pregnantWithChildBirth?.fullName
									? pregnantWithChildBirth?.fullName
									: "N/A"}
							</span>
						</Col>
						<Col sm={12} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-4 text-primary">Data de nascimento: </span>
							<span>
								{pregnantWithChildBirth?.birthdate
									? dayjs(pregnantWithChildBirth?.birthdate).format("DD/MM/YYYY")
									: "N/A"}
							</span>
						</Col>
						<Col sm={12} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-4 text-primary">Idade: </span>
							<span>
								{pregnantWithChildBirth?.age ? pregnantWithChildBirth?.age : "N/A"}
							</span>
						</Col>
						<Col sm={12} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-4 text-primary">Nis: </span>
							<span>{pregnantWithChildBirth?.nis || "N/A"}</span>
						</Col>
						<Col sm={12} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-4 text-primary">Ocupação: </span>
							<span>
								{pregnantWithChildBirth?.citizenCbo?.description
									? `${pregnantWithChildBirth?.citizenCbo?.code} - ${pregnantWithChildBirth?.citizenCbo?.description}`
									: "N/A"}
							</span>
						</Col>
						<Col sm={12} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-4 text-primary">Telefone: </span>
							<span>
								{pregnantWithChildBirth?.phone
									? pregnantWithChildBirth?.phone
									: "N/A"}
							</span>
						</Col>
						<Col sm={12} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-4 text-primary">Fuma: </span>
							<span>
								{pregnantWithChildBirth?.smokerStatus === 1 ? "SIM" : "NÃO"}
							</span>
						</Col>
						<Col sm={12} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-4 text-primary">Bebe: </span>
							<span>
								{pregnantWithChildBirth?.alcoholicStatus === 1 ? "SIM" : "NÃO"}
							</span>
						</Col>
						<Col sm={12} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-4 text-primary">Diabetes: </span>
							<span>
								{pregnantWithChildBirth?.haveDiabetes === 1 ? "SIM" : "NÃO"}
							</span>
						</Col>
						<Col sm={12} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-4 text-primary">Hipertenso: </span>
							<span>
								{pregnantWithChildBirth?.haveHighBloodPressure === 1
									? "SIM"
									: "NÃO"}
							</span>
						</Col>
						<Col sm={12} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-4 text-primary">
								Última atualização cadastral:{" "}
							</span>
							<span>
								{pregnantWithChildBirth?.lastIndividualRecordDate
									? dayjs(pregnantWithChildBirth.lastIndividualRecordDate).format(
											"DD/MM/YYYY"
									  )
									: "N/A"}
							</span>
						</Col>

						<Col sm={12} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-4 text-primary">Unidade: </span>
							<span>
								{pregnantWithChildBirth?.healthUnit?.name
									? pregnantWithChildBirth?.healthUnit.name
									: "N/A"}
							</span>
						</Col>
						<Col sm={12} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-4 text-primary">Equipe: </span>
							<span>
								{pregnantWithChildBirth?.healthTeam?.name
									? pregnantWithChildBirth?.healthTeam?.name
									: "N/A"}
							</span>
						</Col>
						<Col sm={12} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-4 text-primary">Cor ou raça: </span>
							<span>
								{pregnantWithChildBirth?.racialColor === RacialColorEnum.WHITE
									? "BRANCA"
									: pregnantWithChildBirth?.racialColor === RacialColorEnum.BLACK
									? "PRETA"
									: pregnantWithChildBirth?.racialColor === RacialColorEnum.YELLOW
									? "PARDA"
									: pregnantWithChildBirth?.racialColor === RacialColorEnum.BROWN
									? "AMARELA"
									: pregnantWithChildBirth?.racialColor ===
									  RacialColorEnum.INDIGENOUS
									? "INDÍGENA"
									: "N/A"}
							</span>
						</Col>
					</Row>
				</section>
				<h3>INFORMAÇÕES DA GESTAÇÃO</h3>
				<section className="my-3">
					<Row>
						<Col sm={12} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-4 text-primary">DUM: </span>
							<span>
								{pregnantWithChildBirth?.firstDumDate
									? dayjs(pregnantWithChildBirth?.firstDumDate).format(
											"DD/MM/YYYY"
									  )
									: "N/A"}
							</span>
						</Col>
						<Col sm={12} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-4 text-primary">DPP: </span>
							<span>
								{pregnantWithChildBirth?.dppDate
									? dayjs(pregnantWithChildBirth?.dppDate).format("DD/MM/YYYY")
									: "N/A"}
							</span>
						</Col>
						<Col sm={12} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-4 text-primary">1ª IG: </span>
							<span>
								{pregnantWithChildBirth?.firstGestationalAge
									? `${pregnantWithChildBirth?.firstGestationalAge} SEMANAS`
									: "N/A"}
							</span>
						</Col>
						<Col sm={12} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-4 text-primary">Tipo de gravidez: </span>
							<span>
								{pregnantWithChildBirth?.pregnancyType === PregnancyTypeEnum.SINGLE
									? "ÚNICA"
									: pregnantWithChildBirth?.pregnancyType ===
									  PregnancyTypeEnum.DOUBLE
									? "DUPLA"
									: pregnantWithChildBirth?.pregnancyType ===
									  PregnancyTypeEnum.TRIPLEORMORE
									? "TRÊS OU MAIS"
									: pregnantWithChildBirth?.pregnancyType ===
									  PregnancyTypeEnum.IGNORED
									? "IGNORADO"
									: "N/A"}
							</span>
						</Col>
						<Col sm={12} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-4 text-primary">Gravidez de risco: </span>
							<span>
								{pregnantWithChildBirth?.highRiskStatus === 1
									? "SIM"
									: pregnantWithChildBirth?.highRiskStatus === 0
									? "NÃO"
									: "N/A"}
							</span>
						</Col>
						<Col sm={12} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-4 text-primary">Gravidez planejada: </span>
							<span>
								{pregnantWithChildBirth?.plannedPregnancyStatus === 1
									? "SIM"
									: pregnantWithChildBirth?.plannedPregnancyStatus === 0
									? "NÃO"
									: "N/A"}
							</span>
						</Col>
						<Col sm={12} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-4 text-primary">
								Classificação de peso:{" "}
							</span>
							<span>
								{pregnantWithChildBirth?.weightClassification ===
								WeightClassificationEnum.LIGHTWEIGHT
									? "ABAIXO DO PESO"
									: pregnantWithChildBirth?.weightClassification ===
									  WeightClassificationEnum.PROPERWEIGHT
									? "PESO IDEAL"
									: pregnantWithChildBirth?.weightClassification ===
									  WeightClassificationEnum.OVERWEIGHT
									? "ACIMA DO PESO"
									: "N/A"}
							</span>
						</Col>
						<Col sm={12} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-4 text-primary">Peso: </span>
							<span>
								{pregnantWithChildBirth?.attendances &&
								pregnantWithChildBirth?.attendances.length > 0 &&
								pregnantWithChildBirth?.attendances[0].weight
									? `${pregnantWithChildBirth?.attendances[0].weight || 0} KG`
									: "N/A"}
							</span>
						</Col>
						<Col sm={12} md={4} lg={4} xl={4}>
							<span className="fw-bold fs-4 text-primary">Altura: </span>
							<span>
								{pregnantWithChildBirth?.attendances &&
								pregnantWithChildBirth?.attendances.length > 0 &&
								pregnantWithChildBirth?.attendances[0].height
									? `${pregnantWithChildBirth?.attendances[0].height || 0} CM`
									: "N/A"}
							</span>
						</Col>

						{pregnantWithChildBirth.childBirthDate && (
							<>
								<Col sm={12} md={4} lg={4} xl={4}>
									<span className="fw-bold fs-4 text-primary">
										Data do parto:{" "}
									</span>
									<span>
										{dayjs(pregnantWithChildBirth.childBirthDate).format(
											"DD/MM/YYYY"
										)}
									</span>
								</Col>
								<Col sm={12} md={8} lg={8} xl={8}>
									<span className="fw-bold fs-4 text-primary">
										Tipo de parto:{" "}
									</span>
									<span>
										{pregnantWithChildBirth?.childBirthType === "normal"
											? "NORMAL"
											: pregnantWithChildBirth?.childBirthType === "cesarean"
											? "CESÁREO"
											: pregnantWithChildBirth?.childBirthType ===
											  "normalstillbirth"
											? "NATIMORTO (parto normal)"
											: pregnantWithChildBirth?.childBirthType ===
											  "cesareanstillbirth"
											? "NATIMORTO (parto cesário)"
											: pregnantWithChildBirth?.childBirthType === "abortion"
											? "ABORTO"
											: pregnantWithChildBirth?.childBirthType ===
											  "unspecified"
											? "NÃO ESPECIFICADO"
											: pregnantWithChildBirth?.childBirthType ===
											  "unregistered"
											? "NÃO REGISTRADO"
											: "N/A"}
									</span>
								</Col>
							</>
						)}
						{pregnantWithChildBirth.childBirthProblem && (
							<>
								<Col sm={12} md={4} lg={4} xl={4}>
									<span className="fw-bold fs-4 text-primary">CID: </span>
									<span>
										{`${pregnantWithChildBirth.childBirthProblem.cid?.cidCode} - ${pregnantWithChildBirth.childBirthProblem.cid?.description}`}
									</span>
								</Col>
								<Col sm={12} md={8} lg={8} xl={8}>
									<span className="fw-bold fs-4 text-primary">CIAP: </span>
									<span>
										{`${pregnantWithChildBirth.childBirthProblem.ciap?.ciapCode} - ${pregnantWithChildBirth.childBirthProblem.ciap?.description}`}
									</span>
								</Col>
							</>
						)}
					</Row>
				</section>
				{pregnantWithChildBirth?.attendances?.length === 0 ? (
					<div>
						<h1 className="ms-20 fs-5">CONSULTAS</h1>
						<NoRecordsFeedback
							icon={<TbClipboardX />}
							message="Nenhum atendimento foi encontrado"
						/>
					</div>
				) : (
					<TimeLine
						timeLineTitle="CONSULTAS"
						timeLineContents={pregnantWithChildBirth.attendances?.map<TimeLineContent>(
							(attendance, index) => {
								return {
									content: (
										<Row key={`patient-attendance-${index}`}>
											<Col sm={6} md={6} lg={9} xl={9}>
												<div>
													<span className="text-primary">
														PROFISSIONAL:{" "}
													</span>
													<p>
														<span>Nome: </span>
														{attendance?.professional1?.name?.toUpperCase() ||
															"N/A"}
													</p>
													<p>
														<span>CBO: </span>
														{attendance?.cbo1?.description?.toUpperCase() ||
															"N/A"}
													</p>
													<p>
														<span>Unidade: </span>
														{attendance?.healthUnit?.name?.toUpperCase() ||
															"N/A"}
													</p>
													<p>
														<span>Equipe: </span>
														{attendance?.healthTeam?.name?.toUpperCase() ||
															"N/A"}
													</p>
													<span className="text-primary">MOTIVO: </span>
													<p>
														<span>Cid: </span>
														{`${
															attendance?.problems?.at(0)?.cid
																?.cidCode
														} - ${
															attendance?.problems?.at(0)?.cid
																?.description
														}`}
													</p>
													<p>
														<span>Ciap: </span>
														{`${
															attendance?.problems?.at(0)?.ciap
																?.ciapCode
														} - ${
															attendance?.problems?.at(0)?.ciap
																?.description
														}`}
													</p>
												</div>
											</Col>
										</Row>
									),
									eventDate: attendance?.date
										? dayjs(attendance?.date).format("DD/MM/YYYY")
										: "N/A",
								};
							}
						)}
					/>
				)}

				{pregnantWithChildBirth?.attendanceProcedures?.length === 0 ? (
					<div>
						<h1 className="ms-20 fs-5">EXAMES DE HIV E SÍFILIS</h1>
						<NoRecordsFeedback
							icon={<FaVirus />}
							message="Nenhum exame foi encontrado."
						/>
					</div>
				) : (
					<TimeLine
						timeLineTitle="EXAMES DE HIV E SÍFILIS"
						timeLineContents={pregnantWithChildBirth.attendanceProcedures?.map<TimeLineContent>(
							(attendanceProcedure, index) => {
								return {
									content: (
										<Row key={`patient-exams-${index}`}>
											<Col sm={6} md={6} lg={9} xl={9}>
												<div>
													<span className="text-primary">
														PROFISSIONAL:{" "}
													</span>
													<p>
														<span>Nome: </span>
														{attendanceProcedure?.professional?.name?.toUpperCase() ||
															"N/A"}
													</p>
													<p>
														<span>CBO: </span>
														{attendanceProcedure?.cbo?.description?.toUpperCase() ||
															"N/A"}
													</p>
													<span className="text-primary">EXAME: </span>
													<p>
														<span>Procedimento: </span>
														{`${
															attendanceProcedure?.procedure
																?.procedureCode ?? "N/A"
														} - ${
															attendanceProcedure?.procedure?.name ??
															""
														}`}
													</p>
												</div>
											</Col>
										</Row>
									),
									eventDate: attendanceProcedure?.date
										? dayjs(attendanceProcedure?.date).format("DD/MM/YYYY")
										: "N/A",
								};
							}
						)}
					/>
				)}
				{pregnantWithChildBirth?.pregnancyProcedures?.length === 0 ? (
					<div>
						<h1 className="ms-20 fs-5">OUTROS EXAMES GESTACIONAIS</h1>
						<NoRecordsFeedback
							icon={<FaVirus />}
							message="Nenhum exame foi encontrado."
						/>
					</div>
				) : (
					<TimeLine
						timeLineTitle="OUTROS EXAMES GESTACIONAIS"
						timeLineContents={pregnantWithChildBirth.pregnancyProcedures?.map<TimeLineContent>(
							(attendanceProcedure, index) => {
								return {
									content: (
										<Row key={`patient-exams-${index}`}>
											<Col sm={6} md={6} lg={9} xl={9}>
												<div>
													<span className="text-primary">
														PROFISSIONAL:{" "}
													</span>
													<p>
														<span>Nome: </span>
														{attendanceProcedure?.professional1?.name?.toUpperCase() ||
															"N/A"}
													</p>
													<p>
														<span>CBO: </span>
														{attendanceProcedure?.cbo1?.description?.toUpperCase() ||
															"N/A"}
													</p>
													<span className="text-primary">EXAME: </span>
													<p>
														<span>Procedimento: </span>
														{`${
															attendanceProcedure
																?.attProcedureEvaluated
																?.procedureCode ?? "N/A"
														} - ${
															attendanceProcedure
																?.attProcedureEvaluated?.name ?? ""
														}`}
													</p>
												</div>
											</Col>
										</Row>
									),
									eventDate: attendanceProcedure?.date
										? dayjs(attendanceProcedure?.date).format("DD/MM/YYYY")
										: "N/A",
								};
							}
						)}
					/>
				)}
				{pregnantWithChildBirth?.dentalAttendances?.length === 0 ? (
					<div>
						<h1 className="ms-20 fs-5">ATENDIMENTO ODONTOLÓGICO</h1>
						<NoRecordsFeedback
							icon={<FaTooth />}
							message="Nenhum atendimento odontológico foi encontrado."
						/>
					</div>
				) : (
					<TimeLine
						timeLineTitle="ATENDIMENTO ODONTOLÓGICO"
						timeLineContents={pregnantWithChildBirth.dentalAttendances?.map<TimeLineContent>(
							(dentalAttendance, index) => {
								return {
									content: (
										<Row key={`patient-dental-${index}`}>
											<Col sm={6} md={6} lg={9} xl={9}>
												<div>
													<span className="text-primary">
														PROFISSIONAL:{" "}
													</span>
													<p>
														<span>Nome: </span>
														{dentalAttendance?.professional1?.name?.toUpperCase() ||
															"N/A"}
													</p>
													<p>
														<span>Categoria: </span>
														{dentalAttendance?.cbo1?.description?.toUpperCase() ||
															"N/A"}
													</p>
													{dentalAttendance.professional2?.name && (
														<>
															<span className="text-primary">
																PROFISSIONAL 2:
															</span>
															<p>
																<span>Nome: </span>
																{dentalAttendance?.professional2?.name?.toUpperCase() ||
																	"N/A"}
															</p>
															<p>
																<span>Categoria: </span>
																{dentalAttendance?.cbo2?.description?.toUpperCase() ||
																	"N/A"}
															</p>
														</>
													)}
												</div>
											</Col>
										</Row>
									),
									eventDate: dentalAttendance?.date
										? dayjs(dentalAttendance?.date).format("DD/MM/YYYY")
										: "N/A",
								};
							}
						)}
					/>
				)}
				{pregnantWithChildBirth?.pregnancyVaccinations?.length &&
				pregnantWithChildBirth?.pregnancyVaccinations?.length > 0 ? (
					<Row className="mt-4">
						<VaccinationCard
							vaccinations={
								pregnantWithChildBirth.pregnancyVaccinations?.map<VaccinationCardData>(
									(vaccination) => {
										return {
											...vaccination,
											dateofVaccine: vaccination.dateofVaccine
												? dayjs(vaccination.dateofVaccine).format(
														"DD/MM/YYYY"
												  )
												: "",
											status: "vaccinated",
										};
									}
								) || []
							}
						/>
					</Row>
				) : (
					<div>
						<h1 className="ms-20 fs-5">Vacinações</h1>
						<NoRecordsFeedback
							icon={<FaSyringe />}
							message="Nenhuma vacinação foi encontrada no período."
						/>
					</div>
				)}
			</Modal.Body>
		</Modal>
	);
}
