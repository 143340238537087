import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { Account } from "@/@types/Account";
import { RacialColorEnum } from "@/utils/enums/RacialColorEnum";

import { PregnancyDashboardPrenatalHeader } from "./PregnancyDashboardPrenatalHeader";
import { PregnancyDashboardPrenatalResume } from "./PregnancyDashboardPrenatalResume";
import { PregnancyDashboardPrenatalDetails } from "./PregnancyDashboardPrenatalDetails";
import { ResumeGrouped } from "..";

export type PregnancyResume = {
	totalActivePregnants: number;
	pregnancyByAgeResumes: ResumeGrouped<
		| "lessThan15"
		| "greaterOrEqual15LessThan25"
		| "greaterOrEqual25LessThanOrEqual35"
		| "greaterThan35",
		object
	>[];
	pregnancyByRacialColorResumes: ResumeGrouped<RacialColorEnum | undefined, object>[];
	pregnancyByPlannedPregnancyStatusResumes: ResumeGrouped<number | null, object>[];
	pregnancyByHighRiskStatusResumes: ResumeGrouped<number | null, object>[];
};

export type PregnancyResumeCity = PregnancyResume & {
	city: Account;
};

type Props = {
	pregnancyResumeCities: PregnancyResumeCity[];
};

export function PregnancyDashboardPrenatal({ pregnancyResumeCities }: Props) {
	const totalActivePregnants = pregnancyResumeCities
		.map((resume) => resume.totalActivePregnants || 0)
		.reduce((acc, curr) => acc + curr, 0);

	return (
		<section>
			<Row>
				<Col className="m-2">
					<Card className="mx-2">
						<Card.Body>
							<PregnancyDashboardPrenatalHeader />
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row>
				<Col className="mx-2 mb-2">
					<PregnancyDashboardPrenatalResume totalActivePregnants={totalActivePregnants} />
				</Col>
			</Row>
			<Row>
				<Col className="mx-2 mb-2">
					<PregnancyDashboardPrenatalDetails
						pregnancyResumeCities={pregnancyResumeCities}
					/>
				</Col>
			</Row>
		</section>
	);
}
