import { useState } from "react";

import dayjs from "dayjs";

import { TbAlertCircleFilled } from "react-icons/tb";
import { RiCloseCircleFill } from "react-icons/ri";
import { FaCheckCircle, FaEye } from "react-icons/fa";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";

import { OrderBy } from "@/@types/OrderBy";

import { Pregnancy } from "@/pages/PregnancyManagement";
import { TableSkeletons } from "@/components/TableSkeletons";
import { PregnancyModal } from "./PregnancyModal";
import { OrderByButton } from "@/components/OrderByButton";

type Props = {
	isLoading: boolean;
	pregnantsWithChildBirth: Pregnancy[];
	orderBy?: OrderBy<Pregnancy>[];
	setOrderBy?: (orderBy: OrderBy<Pregnancy>[]) => void;
};

export function PregnancyTable({ isLoading, pregnantsWithChildBirth, orderBy, setOrderBy }: Props) {
	const [selectedPregnancy, setSelectedPregnancy] = useState<Pregnancy>({} as Pregnancy);

	const [showPregnantModal, setShowPregnantModal] = useState(false);
	const handleShowPregnantModal = () => {
		setShowPregnantModal(true);
	};
	const handleClosePregnantModal = () => {
		setShowPregnantModal(false);
	};

	function changeOrderBy(property: keyof Pregnancy) {
		const orderItem = (orderBy || []).find((orderItem) => orderItem.property === property);
		if (orderItem) {
			const reverse =
				orderItem.method === "asc"
					? ({ property, method: "desc" } as OrderBy<Pregnancy>)
					: null;
			setOrderBy &&
				setOrderBy(
					(orderBy || [])
						.filter((orderItem) => !(orderItem.property === property))
						.concat(reverse ? [reverse] : [])
				);
		} else {
			setOrderBy && setOrderBy((orderBy || []).concat([{ property, method: "asc" }]));
		}
	}

	return (
		<div>
			<PregnancyModal
				pregnantWithChildBirth={selectedPregnancy}
				show={showPregnantModal}
				onHide={handleClosePregnantModal}
			/>
			<Table
				bordered
				responsive
				striped
				hover
				id="table-pregnantsWithChildBirth"
				className="table align-middle table-row-dashed fs-7 fw-bold dataTable no-footer"
			>
				<thead>
					<tr className="text-center text-muted">
						<th className="border border-0 ps-2">
							<div className="d-flex justify-content-between">
								NOME{" "}
								{orderBy && setOrderBy && (
									<OrderByButton
										handleChangeOrder={changeOrderBy}
										property="fullName"
										method={
											orderBy.find(
												(orderItem) => orderItem.property === "fullName"
											)?.method
										}
									></OrderByButton>
								)}
							</div>
						</th>
						<th className="border border-0">
							<div className="d-flex justify-content-between">
								DN{" "}
								{orderBy && setOrderBy && (
									<OrderByButton
										handleChangeOrder={changeOrderBy}
										property="birthdate"
										method={
											orderBy.find(
												(orderItem) => orderItem.property === "birthdate"
											)?.method
										}
									></OrderByButton>
								)}
							</div>
						</th>
						<th className="border border-0">
							<div className="d-flex justify-content-between">
								DUM{" "}
								{orderBy && setOrderBy && (
									<OrderByButton
										handleChangeOrder={changeOrderBy}
										property="firstDumDate"
										method={
											orderBy.find(
												(orderItem) => orderItem.property === "firstDumDate"
											)?.method
										}
									></OrderByButton>
								)}
							</div>
						</th>
						<th className="border border-0">
							<div className="d-flex justify-content-between">
								1ª IG{" "}
								{orderBy && setOrderBy && (
									<OrderByButton
										handleChangeOrder={changeOrderBy}
										property="firstGestationalAge"
										method={
											orderBy.find(
												(orderItem) =>
													orderItem.property === "firstGestationalAge"
											)?.method
										}
									></OrderByButton>
								)}
							</div>
						</th>
						<th className="border border-0">
							<div className="d-flex justify-content-between">
								ÚLTIMA IG{" "}
								{orderBy && setOrderBy && (
									<OrderByButton
										handleChangeOrder={changeOrderBy}
										property="lastGestationalAge"
										method={
											orderBy.find(
												(orderItem) =>
													orderItem.property === "lastGestationalAge"
											)?.method
										}
									></OrderByButton>
								)}
							</div>
						</th>
						<th className="border border-0">
							<div className="d-flex justify-content-between">
								PARTO{" "}
								{orderBy && setOrderBy && (
									<OrderByButton
										handleChangeOrder={changeOrderBy}
										property="childBirthDate"
										method={
											orderBy.find(
												(orderItem) =>
													orderItem.property === "childBirthDate"
											)?.method
										}
									></OrderByButton>
								)}
							</div>
						</th>
						<th>TIPO PARTO</th>
						<th>PUERPERIO</th>
						<th>CONSULTAS</th>
						<th className="no-print">AÇÕES</th>
					</tr>
				</thead>
				<tbody className="table-tbody" id="pregnantsWithChildBirth">
					{!isLoading && pregnantsWithChildBirth?.length > 0 ? (
						pregnantsWithChildBirth?.map((pregnant: Pregnancy, index: number) => (
							<tr
								key={`pregnantsWithChildBirth-${index}`}
								className="text-center border-bottom-1 border-dark ms-2"
							>
								<td title="nome" className="text-start text-nowrap ps-2">
									{pregnant.fullName}{" "}
								</td>
								<td title={pregnant.age} className="text-start text-nowrap">
									{pregnant.birthdate
										? dayjs(pregnant.birthdate).format("DD/MM/YYYY")
										: ""}
								</td>
								<td title="DUM" className="text-start text-nowrap">
									{pregnant.firstDumDate
										? dayjs(pregnant.firstDumDate).format("DD/MM/YYYY")
										: "-"}
								</td>
								<td title="1ª IG" className="text-start">
									{pregnant.firstGestationalAge
										? `${pregnant.firstGestationalAge} semanas`
										: "-"}
								</td>
								<td title="Última IG" className="text-start">
									{pregnant.lastGestationalAge
										? `${pregnant.lastGestationalAge} semanas`
										: "-"}
								</td>
								<td title="Parto" className="text-start">
									{pregnant.childBirthDate
										? dayjs(pregnant.childBirthDate).format("DD/MM/YYYY")
										: "-"}
								</td>
								<td title="Tipo de parto">
									{pregnant.childBirthType === "normal"
										? "Normal"
										: pregnant.childBirthType === "cesarean"
										? "Cesário"
										: pregnant.childBirthType === "abortion"
										? "Aborto"
										: pregnant.childBirthType === "normalstillbirth"
										? "Natimorto (normal)"
										: pregnant.childBirthType === "cesareanstillbirth"
										? "Natimorto (cesário)"
										: pregnant.childBirthType === "unspecified"
										? "Não especificado"
										: pregnant.childBirthType === "unregistered"
										? "Não registrado"
										: "-"}
								</td>
								<td title="Puerperio">
									{pregnant.puerperalStatus === "finished" ? (
										<FaCheckCircle className="text-success fs-2" />
									) : pregnant.puerperalStatus === "pending" ? (
										<TbAlertCircleFilled className="text-warning fs-2" />
									) : pregnant.puerperalStatus === "lost" ? (
										<RiCloseCircleFill className="text-danger fs-2" />
									) : null}
								</td>
								<td title="Consultas">{pregnant.attendances?.length}</td>
								<td className="no-print">
									<div className="d-flex justify-content-center gap-2 w-100">
										<Button
											title="Visualizar detalhamento"
											variant="primary"
											className="fs-8 px-5 py-2 ml-auto"
											onClick={() => {
												setSelectedPregnancy(pregnant);
												handleShowPregnantModal();
											}}
										>
											<FaEye />
										</Button>
									</div>
								</td>
							</tr>
						))
					) : (
						<TableSkeletons numberOfCells={10} numberOfRows={10} />
					)}
				</tbody>
			</Table>
		</div>
	);
}
