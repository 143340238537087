import Table from "react-bootstrap/Table";

import { Account } from "@/@types/Account";

import { ResumeGrouped } from "../../..";

export type PregnancyByCityAndPlannedPregnancyResume = {
	resumes: ResumeGrouped<number | null, object>[];
	city: Account;
};

type Props = {
	pregnancyByCityAndPlannedPregnancyResumes: PregnancyByCityAndPlannedPregnancyResume[];
};

export function PregnancyByCityAndPlannedPregnancyTable({
	pregnancyByCityAndPlannedPregnancyResumes,
}: Props) {
	return (
		<Table
			bordered
			responsive
			striped
			hover
			id="table-pregnancy-city-plannedpregnancy"
			className="table border border-1 align-middle table-row-dashed fs-7 fw-bold dataTable no-footer"
		>
			<thead>
				<tr className="text-center text-muted">
					<th>Município</th>
					<th>Planejada</th>
					<th>Não planejada</th>
					<th>Não informada</th>
					<th>Total</th>
				</tr>
			</thead>
			<tbody className="table-tbody text-center">
				{pregnancyByCityAndPlannedPregnancyResumes
					.sort((a, b) => a.city.locale.localeCompare(b.city.locale))
					.map((cityResume, index) => (
						<tr key={`table-pregnancy-city-plannedpregnancy-${index}`}>
							<td>{cityResume.city.locale}</td>
							<td>
								{cityResume.resumes.find((resume) => resume.groupKey === 1)
									?.groupCount || 0}
							</td>
							<td>
								{cityResume.resumes.find((resume) => resume.groupKey === 0)
									?.groupCount || 0}
							</td>
							<td>
								{cityResume.resumes.find((resume) => resume.groupKey === undefined)
									?.groupCount || 0}
							</td>
							<td>
								{cityResume.resumes
									.map((resume) => resume.groupCount || 0)
									.reduce((acc, curr) => acc + curr, 0)}
							</td>
						</tr>
					))}
			</tbody>
		</Table>
	);
}
