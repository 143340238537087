import { useState } from "react";

import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import { RacialColorEnum } from "@/utils/enums/RacialColorEnum";

import { BarChart } from "@/components/Charts/BarChart";
import { DonutChart } from "@/components/Charts/DonutChart";
import { PeopleChart, RacialColor } from "@/components/Charts/PeopleChart";
import { PregnancyResumeCity } from "..";
import { PregnancyByCityAndAgeModal } from "./PregnancyByCityAndAgeModal";
import { PregnancyByCityAndRacialColorModal } from "./PregnancyByCityAndRacialColorModal";
import { PregnancyByCityAndHighRiskModal } from "./PregnancyByCityAndHighRiskModal";
import { PregnancyByCityAndPlannedPregnancyModal } from "./PregnancyByCityAndPlannedPregnancyModal";

type Props = {
	pregnancyResumeCities: PregnancyResumeCity[];
};

export function PregnancyDashboardPrenatalDetails({ pregnancyResumeCities }: Props) {
	const [showPregnancyByCityAndAgeModal, setShowPregnancyByCityAndAgeModal] = useState(false);
	const [showPregnancyByCityAndRacialColorModal, setShowPregnancyByCityAndRacialColorModal] =
		useState(false);
	const [showPregnancyByCityAndHighRiskModal, setShowPregnancyByCityAndHighRiskModal] =
		useState(false);
	const [
		showPregnancyByCityAndPlannedPregnancyModal,
		setShowPregnancyByCityAndPlannedPregnancyModal,
	] = useState(false);
	const pregnantsByAgeResume = [
		{
			category: "< 15 anos",
			value: pregnancyResumeCities
				.map(
					(resume) =>
						resume.pregnancyByAgeResumes?.find(
							(ageResume) => ageResume.groupKey === "lessThan15"
						)?.groupCount || 0
				)
				.reduce((acc, curr) => acc + curr, 0),
		},
		{
			category: "15 - 25 anos",
			value: pregnancyResumeCities
				.map(
					(resume) =>
						resume.pregnancyByAgeResumes?.find(
							(ageResume) => ageResume.groupKey === "greaterOrEqual15LessThan25"
						)?.groupCount || 0
				)
				.reduce((acc, curr) => acc + curr, 0),
		},
		{
			category: "25 - 35 anos",
			value: pregnancyResumeCities
				.map(
					(resume) =>
						resume.pregnancyByAgeResumes?.find(
							(ageResume) =>
								ageResume.groupKey === "greaterOrEqual25LessThanOrEqual35"
						)?.groupCount || 0
				)
				.reduce((acc, curr) => acc + curr, 0),
		},
		{
			category: "> 35 anos",
			value: pregnancyResumeCities
				.map(
					(resume) =>
						resume.pregnancyByAgeResumes?.find(
							(ageResume) => ageResume.groupKey === "greaterThan35"
						)?.groupCount || 0
				)
				.reduce((acc, curr) => acc + curr, 0),
		},
	];

	const racialDistribution: Record<RacialColor, number> = {
		white: pregnancyResumeCities
			.map(
				(resume) =>
					resume.pregnancyByRacialColorResumes?.find(
						(racialColorResume) =>
							racialColorResume.groupKey === RacialColorEnum.WHITE ||
							!racialColorResume.groupKey
					)?.groupCount || 0
			)
			.reduce((acc, curr) => acc + curr, 0),
		black: pregnancyResumeCities
			.map(
				(resume) =>
					resume.pregnancyByRacialColorResumes?.find(
						(racialColorResume) => racialColorResume.groupKey === RacialColorEnum.BLACK
					)?.groupCount || 0
			)
			.reduce((acc, curr) => acc + curr, 0),
		yellow: pregnancyResumeCities
			.map(
				(resume) =>
					resume.pregnancyByRacialColorResumes?.find(
						(racialColorResume) => racialColorResume.groupKey === RacialColorEnum.YELLOW
					)?.groupCount || 0
			)
			.reduce((acc, curr) => acc + curr, 0),
		indigenous: pregnancyResumeCities
			.map(
				(resume) =>
					resume.pregnancyByRacialColorResumes?.find(
						(racialColorResume) =>
							racialColorResume.groupKey === RacialColorEnum.INDIGENOUS
					)?.groupCount || 0
			)
			.reduce((acc, curr) => acc + curr, 0),
		brown: pregnancyResumeCities
			.map(
				(resume) =>
					resume.pregnancyByRacialColorResumes?.find(
						(racialColorResume) => racialColorResume.groupKey === RacialColorEnum.BROWN
					)?.groupCount || 0
			)
			.reduce((acc, curr) => acc + curr, 0),
	};

	const plannedPregnancyResume = [
		{
			category: "Sim",
			color: "#0f9beb",
			value: pregnancyResumeCities
				.map(
					(resume) =>
						resume.pregnancyByPlannedPregnancyStatusResumes?.find(
							(plannedPregnantResume) => plannedPregnantResume.groupKey === 1
						)?.groupCount || 0
				)
				.reduce((acc, curr) => acc + curr, 0),
		},
		{
			category: "Não",
			color: "#f1416c",
			value: pregnancyResumeCities
				.map(
					(resume) =>
						resume.pregnancyByPlannedPregnancyStatusResumes?.find(
							(plannedPregnantResume) => plannedPregnantResume.groupKey === 0
						)?.groupCount || 0
				)
				.reduce((acc, curr) => acc + curr, 0),
		},
		{
			category: "Não informado",
			color: "#bdcfd9",
			value: pregnancyResumeCities
				.map(
					(resume) =>
						resume.pregnancyByPlannedPregnancyStatusResumes?.find(
							(plannedPregnantResume) => plannedPregnantResume.groupKey === undefined
						)?.groupCount || 0
				)
				.reduce((acc, curr) => acc + curr, 0),
		},
	];

	const highRiskPregnancyResume = [
		{
			category: "Alto risco",
			color: "#f1416c",
			value: pregnancyResumeCities
				.map(
					(resume) =>
						resume.pregnancyByHighRiskStatusResumes?.find(
							(highRiskResume) => highRiskResume.groupKey === 1
						)?.groupCount || 0
				)
				.reduce((acc, curr) => acc + curr, 0),
		},
		{
			category: "Baixo risco",
			color: "#0f9beb",
			value: pregnancyResumeCities
				.map(
					(resume) =>
						resume.pregnancyByHighRiskStatusResumes?.find(
							(highRiskResume) => highRiskResume.groupKey === 0
						)?.groupCount || 0
				)
				.reduce((acc, curr) => acc + curr, 0),
		},
	];

	return (
		<section>
			<Row className="px-2">
				<Col sm={12} md={12} lg={6} xl={6} className="pb-2 px-3">
					<Card className="h-100">
						<Card.Header>
							<Card.Title className="d-flex justify-content-between w-100">
								<h3>Gestantes por faixa etária: </h3>
								<Button
									className="no-print"
									variant="primary"
									onClick={() => setShowPregnancyByCityAndAgeModal(true)}
								>
									Detalhes
								</Button>
							</Card.Title>
						</Card.Header>
						<Card.Body>
							<BarChart
								categories={pregnantsByAgeResume.map(
									(ageResume) => ageResume.category
								)}
								series={[
									{
										data: pregnantsByAgeResume.map(
											(ageResume) => ageResume.value
										),
										name: "Gestantes",
									},
								]}
								isVertical
							/>
						</Card.Body>
					</Card>
				</Col>
				<Col sm={12} md={12} lg={6} xl={6} className="pb-2 px-3">
					<Card className="h-100">
						<Card.Header>
							<Card.Title className="d-flex justify-content-between w-100">
								<h3>Gestantes por raça/cor: </h3>
								<Button
									className="no-print"
									variant="primary"
									onClick={() => setShowPregnancyByCityAndRacialColorModal(true)}
								>
									Detalhes
								</Button>
							</Card.Title>
						</Card.Header>
						<Card.Body className="d-flex p-2 align-items-center justify-content-center">
							<PeopleChart racialColorDistribution={racialDistribution} />
						</Card.Body>
					</Card>
				</Col>
				<Col sm={12} md={12} lg={6} xl={6} className="py-2 px-3">
					<Card className="h-100">
						{/*}
						<Card.Header>
							<Card.Title>
								<h3>Captação precoce: </h3>
							</Card.Title>
						</Card.Header>
						<Card.Body>
							<DonutChart
								labels={["1º trimestre", "2º trimestre", "3º trimestre"]}//highRiskPregnancyResume.map((resume) => resume.category)}
								series={highRiskPregnancyResume.map((resume) => resume.value)}
								width={"100%"}
								colors={highRiskPregnancyResume.map((resume) => resume.color)}
							/>
						</Card.Body>
						*/}
						<Card.Header>
							<Card.Title className="d-flex justify-content-between w-100">
								<h3>Gravidez planejada: </h3>
								<Button
									className="no-print"
									variant="primary"
									onClick={() =>
										setShowPregnancyByCityAndPlannedPregnancyModal(true)
									}
								>
									Detalhes
								</Button>
							</Card.Title>
						</Card.Header>
						<Card.Body>
							<DonutChart
								labels={plannedPregnancyResume.map((resume) => resume.category)}
								series={plannedPregnancyResume.map((resume) => resume.value)}
								width={"100%"}
								colors={plannedPregnancyResume.map((resume) => resume.color)}
							/>
						</Card.Body>
					</Card>
				</Col>
				<Col sm={12} md={12} lg={6} xl={6} className="py-2 px-3">
					<Card className="h-100">
						<Card.Header>
							<Card.Title className="d-flex justify-content-between w-100">
								<h3>Risco gestacional: </h3>
								<Button
									className="no-print"
									variant="primary"
									onClick={() => setShowPregnancyByCityAndHighRiskModal(true)}
								>
									Detalhes
								</Button>
							</Card.Title>
						</Card.Header>
						<Card.Body>
							<DonutChart
								labels={highRiskPregnancyResume.map((resume) => resume.category)}
								series={highRiskPregnancyResume.map((resume) => resume.value)}
								width={"100%"}
								colors={highRiskPregnancyResume.map((resume) => resume.color)}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<PregnancyByCityAndAgeModal
				pregnancyByCityAndAgeResumes={pregnancyResumeCities.map((resume) => {
					return { city: resume.city, resumes: resume.pregnancyByAgeResumes };
				})}
				show={showPregnancyByCityAndAgeModal}
				onHide={() => setShowPregnancyByCityAndAgeModal(false)}
			/>

			<PregnancyByCityAndRacialColorModal
				pregnancyByCityAndRacialColorResumes={pregnancyResumeCities.map((resume) => {
					return { city: resume.city, resumes: resume.pregnancyByRacialColorResumes };
				})}
				show={showPregnancyByCityAndRacialColorModal}
				onHide={() => setShowPregnancyByCityAndRacialColorModal(false)}
			/>

			<PregnancyByCityAndPlannedPregnancyModal
				pregnancyByCityAndPlannedPregnancyResumes={pregnancyResumeCities.map((resume) => {
					return {
						city: resume.city,
						resumes: resume.pregnancyByPlannedPregnancyStatusResumes,
					};
				})}
				show={showPregnancyByCityAndPlannedPregnancyModal}
				onHide={() => setShowPregnancyByCityAndPlannedPregnancyModal(false)}
			/>

			<PregnancyByCityAndHighRiskModal
				pregnancyByCityAndHighRiskResumes={pregnancyResumeCities.map((resume) => {
					return { city: resume.city, resumes: resume.pregnancyByHighRiskStatusResumes };
				})}
				show={showPregnancyByCityAndHighRiskModal}
				onHide={() => setShowPregnancyByCityAndHighRiskModal(false)}
			/>
		</section>
	);
}
