import { useEffect, useState } from "react";
import dayjs from "dayjs";

import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { FaUser } from "react-icons/fa";

import { useLayout } from "@/../_metronic/layout/core";
import { useApi } from "@/hooks/useApi";
import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { useHealthTeams } from "@/hooks/useHealthTeam";
import { useUnits } from "@/hooks/useUnits";
import { Paginated } from "@/@types/paginated";
import { useDebounce } from "@/hooks/useDebounce";

import { PaginationLinks } from "@/components/PaginationLinks";
import { NoRecordsFeedback } from "@/components/NoRecordsFeedback";
import { TripleViralCitizenVaccinationsModal } from "./TripleViralCitizenVaccinationsModal";
import { CitizenVaccinations, TripleViralCitizensTable } from "./TripleViralCitizensTable";
import { TripleViralCitizensFilters } from "./TripleViralCitizensFilters";
import { TripleViralResumes } from "./TripleViralResumes";
import { TripleViralCitizensResumeFilters } from "./TripleViralResumes/TripleViralCitizensResumeFilters";

export type TripleViralResumeType = {
	identified: number;
	finished: number;
	pending: number;
	delayed: number;
	abandoned: number;
	lost: number;
	totalD1: number;
	totalD2: number;
	totalDU: number;
};

export function TripleViralManagement() {
	const { setTitle } = useLayout();
	const { uf, ibgeCode } = useCurrentAccount();
	const { healthTeams } = useHealthTeams();
	const { units } = useUnits();
	const api = useApi();
	const pageSize = 10;
	const [fullName, setFullName] = useState("");
	const debouncedFullName = useDebounce(fullName, 1000);
	const [vaccinationStatus, setVaccinationStatus] = useState("");
	const [startDate, setStartDate] = useState(dayjs().subtract(1, "month").format("YYYY-MM-DD"));
	const [endDate, setEndDate] = useState(dayjs().format("YYYY-MM-DD"));
	const [selectedCnes, setSelectedCnes] = useState("");
	const [selectedIne, setSelectedIne] = useState("");
	const [paginatedCitizensVaccinations, setPaginatedCitizensVaccinations] = useState<
		Paginated<CitizenVaccinations>
	>({} as Paginated<CitizenVaccinations>);
	const [pageNumber, setPageNumber] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [selectedCitizenVaccinations, setSelectedCitizenVaccinations] =
		useState<CitizenVaccinations>({} as CitizenVaccinations);
	const [tripleViralResume, setTripleViralResume] = useState<TripleViralResumeType>(
		{} as TripleViralResumeType
	);
	const [showTripleViralCitizenVaccinationsModal, setShowTripleViralCitizenVaccinationsModal] =
		useState(false);

	async function getTripleViralResume(
		uf: string,
		ibgeCode: string,
		startDate: string,
		endDate: string,
		cnes: string,
		ine: string
	) {
		if (!uf || !ibgeCode) return {} as TripleViralResumeType;
		try {
			const { data } = await api.get<TripleViralResumeType>(
				"/vaccination/v1/ChildTripleViralResume",
				{ params: { uf, ibgeCode, startDate, endDate, cnes, ine } }
			);
			return data;
		} catch (error) {
			console.log(error);
			return {} as TripleViralResumeType;
		}
	}

	async function getPaginatedTripleViralCitizensVaccinations(
		uf: string,
		ibgeCode: string,
		startDate: string,
		endDate: string,
		cnes: string,
		ine: string,
		fullName: string,
		vaccinationStatus: string,
		pageNumber: number
	) {
		if (!uf || !ibgeCode) return {} as Paginated<CitizenVaccinations>;
		try {
			const { data } = await api.get<Paginated<CitizenVaccinations>>(
				"/vaccination/v1/childtripleviralvaccinations",
				{
					params: {
						uf,
						ibgeCode,
						startDate,
						endDate,
						cnes,
						ine,
						fullName,
						vaccinationStatus,
						pageNumber,
						pageSize: pageSize || 10,
					},
				}
			);
			return data;
		} catch (error) {
			console.log(error);
			return {} as Paginated<CitizenVaccinations>;
		}
	}

	async function handleFilterResume() {
		setIsLoading(true);
		setTripleViralResume(
			await getTripleViralResume(uf, ibgeCode, startDate, endDate, selectedCnes, selectedIne)
		);
		setIsLoading(false);
		if (pageNumber !== 1) {
			setPageNumber(1);
		} else {
			handleFilter();
		}
	}

	async function handleFilter() {
		setIsLoading(true);
		setPaginatedCitizensVaccinations({} as Paginated<CitizenVaccinations>);
		setPaginatedCitizensVaccinations(
			await getPaginatedTripleViralCitizensVaccinations(
				uf,
				ibgeCode,
				startDate,
				endDate,
				selectedCnes,
				selectedIne,
				fullName,
				vaccinationStatus,
				pageNumber
			)
		);
		setIsLoading(false);
	}

	function handleOpenTripleViralCitizenVaccinationsModal(
		citizenVaccinations: CitizenVaccinations
	) {
		setSelectedCitizenVaccinations(citizenVaccinations);
		setShowTripleViralCitizenVaccinationsModal(true);
	}

	function handleCloseTripleViralCitizenVaccinationsModal() {
		setShowTripleViralCitizenVaccinationsModal(false);
	}

	useEffect(() => {
		if (pageNumber !== 1) {
			setPageNumber(1);
		} else {
			handleFilter();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedFullName, vaccinationStatus]);

	useEffect(() => {
		if (pageNumber !== 0) {
			handleFilter();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageNumber]);

	useEffect(() => {
		setTitle("VACINAÇÃO TRÍPLICE VIRAL EM CRIANÇAS ENTRE 1 E 2 ANOS");
		handleFilterResume();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="mx-4">
			<Row className="my-2">
				<Col>
					<Card>
						<Card.Body className="py-4">
							<TripleViralCitizensResumeFilters
								setSelectedCnes={setSelectedCnes}
								healthUnits={units}
								setSelectedIne={setSelectedIne}
								healthTeams={healthTeams}
								startDate={startDate}
								setStartDate={setStartDate}
								endDate={endDate}
								setEndDate={setEndDate}
								handleFilter={handleFilterResume}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row className="my-2">
				<Col>
					<TripleViralResumes tripleViralResume={tripleViralResume} />
				</Col>
			</Row>
			<Row className="my-2">
				<Col>
					<Card>
						<Card.Body>
							<TripleViralCitizensFilters
								fullName={fullName}
								setFullName={setFullName}
								setVaccinationStatus={setVaccinationStatus}
								handleFilter={handleFilter}
							/>
							<TripleViralCitizensTable
								citizensVaccinations={paginatedCitizensVaccinations.data}
								handleViewCitizenVaccinations={
									handleOpenTripleViralCitizenVaccinationsModal
								}
								isLoading={isLoading}
							/>
						</Card.Body>
						<Card.Footer>
							{paginatedCitizensVaccinations.totalRecords ? (
								<PaginationLinks
									itemsPerPage={paginatedCitizensVaccinations.pageSize}
									totalPages={paginatedCitizensVaccinations.totalRecords}
									changeSelectedPage={setPageNumber}
									pageNumber={pageNumber || 0}
								/>
							) : (
								!isLoading && (
									<NoRecordsFeedback
										message={"Nenhum cidadão encontrado."}
										icon={<FaUser />}
									/>
								)
							)}
						</Card.Footer>
					</Card>
				</Col>
			</Row>
			<TripleViralCitizenVaccinationsModal
				show={showTripleViralCitizenVaccinationsModal}
				onHide={handleCloseTripleViralCitizenVaccinationsModal}
				citizenVaccinations={selectedCitizenVaccinations}
			/>
		</div>
	);
}
