import Table from "react-bootstrap/Table";

import { Account } from "@/@types/Account";

import { ResumeGrouped } from "../../..";

export type PregnancyByCityAndAgeResume = {
	resumes: ResumeGrouped<
		| "lessThan15"
		| "greaterOrEqual15LessThan25"
		| "greaterOrEqual25LessThanOrEqual35"
		| "greaterThan35",
		object
	>[];
	city: Account;
};

type Props = {
	pregnancyByCityAndAgeResumes: PregnancyByCityAndAgeResume[];
};

export function PregnancyByCityAndAgeTable({ pregnancyByCityAndAgeResumes }: Props) {
	return (
		<Table
			bordered
			responsive
			striped
			hover
			id="table-pregnancy-city-age"
			className="table border border-1 align-middle table-row-dashed fs-7 fw-bold dataTable no-footer"
		>
			<thead>
				<tr className="text-center text-muted">
					<th>Município</th>
					<th>{"< 15"}</th>
					<th>{"15 - 25"}</th>
					<th>{"25 - 35"}</th>
					<th>{"> 35"}</th>
					<th>Total</th>
				</tr>
			</thead>
			<tbody className="table-tbody text-center">
				{pregnancyByCityAndAgeResumes
					.sort((a, b) => a.city.locale.localeCompare(b.city.locale))
					.map((cityResume, index) => (
						<tr key={`table-pregnancy-city-age-${index}`}>
							<td>{cityResume.city.locale}</td>
							<td>
								{cityResume.resumes.find(
									(resume) => resume.groupKey === "lessThan15"
								)?.groupCount || 0}
							</td>
							<td>
								{cityResume.resumes.find(
									(resume) => resume.groupKey === "greaterOrEqual15LessThan25"
								)?.groupCount || 0}
							</td>
							<td>
								{cityResume.resumes.find(
									(resume) =>
										resume.groupKey === "greaterOrEqual25LessThanOrEqual35"
								)?.groupCount || 0}
							</td>
							<td>
								{cityResume.resumes.find(
									(resume) => resume.groupKey === "greaterThan35"
								)?.groupCount || 0}
							</td>
							<td>
								{cityResume.resumes
									.map((resume) => resume.groupCount || 0)
									.reduce((acc, curr) => acc + curr, 0)}
							</td>
						</tr>
					))}
			</tbody>
		</Table>
	);
}
